<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-10">
              <v-toolbar color="#002441" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="E-mail"
                    prepend-icon="mdi-email"
                    type="text"
                    v-model="email"
                    v-on:keyup.enter="logIn"
                    :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    color="#002441"
                  ></v-text-field>

                  <v-text-field
                    label="Password"
                    prepend-icon="mdi-lock"
                    v-model="password"
                    type="password"
                    v-on:keyup.enter="logIn"
                    :rules="[(v) => !!v || 'Password is required']"
                    color="#002441"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#002441" @click.prevent="logIn">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data() {
    return {
      email: "",
      password: "",
      snackbar: false,
      text: "",
      color: "success",
    };
  },
  methods: {
    logIn() {
      if (this.email == "" || this.password == "") {
        this.text = "Provide required credentials";
        this.color = "red";
        this.snackbar = true;
      } else {
        axios
          .post("/user/dummy_login/", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response.data.success && response.data.user) {
              this.text = "Succefully logged in !!";
              this.color = "green";
              this.snackbar = true;
              localStorage.setItem("user", JSON.stringify(response.data.user));
              let { role } = response.data.user;
              if (typeof role == "string" && role.length) {
                if (role.toLowerCase() == "seller") {
                  this.$router.push("/merchantdashboard");
                } else if (role.toLowerCase() == "manager") {
                  this.$router.push("/managerdashboard");
                } else if (role.toLowerCase() == "delivery man") {
                  this.$router.push("/deliverydashboard");
                } else if (role.toLowerCase() == "support") {
                  this.$router.push("/supportdashboard");
                } else if (role.toLowerCase() == "admin") {
                  this.$router.push("/dashboard");
                } else if (role.toLowerCase() == "merchant_manager") {
                  this.$router.push("/merchant-managerdashboard");
                } else {
                  this.text = "Please , provide valid informations";
                  this.color = "red";
                  this.snackbar = true;
                }
              } else {
                this.text = "Please , provide valid informations";
                this.color = "red";
                this.snackbar = true;
              }
            } else {
              this.text = "Please , provide valid informations";
              this.color = "red";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = "Something went wrong !";
            this.color = "red";
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

